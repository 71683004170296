import unidecode from 'unidecode'

export const convertTextToSlug = (text: string) => {
  // convert chars to their closest non latin equivalents and slugify the string

  return unidecode(text)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, '')
}
